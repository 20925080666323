import './App.css';
import React, {Component} from 'react';
import {Runtime, Inspector} from "@observablehq/runtime";
import define from "@berhardki/wie-gelingt-die-energiewende";

class Home extends Component {
  runtime = new Runtime();
  mainRef = React.createRef();
  
  componentDidMount() {
    // create reference to the whole notebook:
    // new Promise(resolve => setTimeout(resolve, ms));
    this.runtime.module(define, Inspector.into(this.mainRef.current));  
    console.log(Object.keys(this.runtime).length);
  }

  render() {
    // return reference to whole notebook: 
    // console.log(document.readyState);
    // onreadystatechange
    
    return (
      <div ref={this.mainRef} />
    )
  }
}

export default Home;