import React from "react";

function Impressum() {
  return (
    <div className="impressum">
        <h1>Impressum</h1>
            
            <h3>Diensteanbieter im Sinne MStV/TMG:</h3>
            <p>
                Science Media Center Germany gGmbH<br/>
                Schloss-Wolfsbrunnenweg 33<br/>
                69118 Heidelberg<br/>
                Handelsregister: HRB 335493<br/>
                Registergericht: Amtsgericht Mannheim<br/>
            </p>
            
            <h3>Vertretungsberechtigter Geschäftsführer:</h3>
            <p>Volker Stollorz</p>
            
            <h3>Verantwortlich für das redaktionelle Angebot (Webmaster) im Sinne des §18 Abs. 2 MStV:</h3>
            <p>Volker Stollorz</p>

            <h3>Ihre Ansprechpartner in Redaktion und SMC Lab</h3>
            <p>Sönke Gäthke, Redakteur für Energie und Technik </p>
            <p>Bernhard Armingeon, Software Entwickler im SMC Lab</p>
            
            <h3>Redaktionssitz</h3>
            <p>
                Science Media Center Germany gGmbH<br/>
                Rosenstr. 42-44<br/>
                50678 Köln<br/>
                Telefon +49 221 8888 25-0<br/>
                Telefax +49 221 8888 25-29<br/>
                info(at)sciencemediacenter(dot)de<br/>
            </p>
            
            <h3>Datenschutz</h3>
            <p>
                Wir nehmen den Schutz persönlicher Daten sehr ernst und halten uns strikt an die Regeln der Datenschutzgesetze. Persönliche Daten speichern wir in Übereinstimmung mit den in Deutschland geltenden Datenschutzbestimmungen.<br/>
                <a href="/datenschutz.html" target="_blank">Datenschutz</a>
            </p>
            
            <h3>Haftung für Inhalte</h3>
            <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>

            <h3>Haftung für Links</h3>
            <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <h3>Urheberrecht</h3>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
    </div>
  );
}

export default Impressum;